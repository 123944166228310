.wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
}
.map{
    position: relative;
    width:400px;
    height: 300px;
}
.mapboxgl-popup-content{
    color: white;
    background:rgba(0, 121, 191, 1);
}
.contatti{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}
.contatto{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.contattoInfo h3{
    line-height: 30px;
    font-weight: 400;
}
.contattoInfo p{
    font-weight: 200;
}
.contattoInfo a{
    font-weight: 200;
    font-size: 16px;
    text-decoration: none;
    color: black;
    transition: all 0.3s ease;
}
.contattoInfo a:hover{
    color: rgba(0, 121, 191, 1);
}
.contattoInfo p:hover{
    color: rgba(0, 121, 191, 1);
}

.contattoImg{
    position: relative;
    background-color:rgba(0, 0, 0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0,0.4);;
    border-radius: 100%;
    margin-right: 10px;
    transition: all 0.3s ease;
}
.contattoImg .bg:hover{
    background: rgba(0, 121, 191, 1);
    
}
.contattoImg .bg:hover + img{
    filter: invert(100%);
}

.contattoImg img{
    
    z-index: 10;
    position: absolute;
    width: 24px;
    height: 24px;
}
.contattoImg .bg{
    width: 100%;
    height: 100%;
    background: rgba(0, 121, 191, 0);
    border-radius: 100%;
    transition: all 0.3s ease;
}
.title{
    margin-bottom: 30px;
    text-align: center;
}
.contattiWrapper{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}