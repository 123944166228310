html {
    color: #000;
    background: #FFF;
}
 
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3,
h4, h5, h6, pre, code, form, fieldset, legend,
input, button, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}
 
table {
    border-collapse: collapse;
    border-spacing: 0;
}
 
fieldset, img {
    border: 0;
}
 
address, caption, cite, code, dfn, em, strong, 
th, var, optgroup {
    font-style: inherit;
    font-weight: inherit;
}
 
del, ins {
    text-decoration: none;
}
 
li {
    list-style: none;
}
 
caption, th {
    text-align: left;
}
 
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}
 
q:before, q:after {
    content: '';
}
 
abbr, acronym {
    border: 0;
    font-variant: normal;
}
 
sup {
    vertical-align: baseline;
}
 
sub {
    vertical-align: baseline;
}
 
/*because legend doesn't inherit in IE */
legend {
    color: #000;
}
 
input, button, textarea, select, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}
 
/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input, button, textarea, select {
    *font-size: 100%;
}    
    
    
    /*
  	Flaticon icon font: Flaticon
  	Creation date: 02/03/2018 11:30
  	*/

    @font-face {
      font-family: "Flaticon";
      src: url("./font/Flaticon.eot");
      src: url("./font/Flaticon.eot?#iefix") format("embedded-opentype"),
           url("./font/Flaticon.woff") format("woff"),
           url("./font/Flaticon.ttf") format("truetype"),
           url("./font/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }
    
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("./font/Flaticon.svg#Flaticon") format("svg");
      }
    }
    
    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      font-size: 150%;
      font-style: normal;
    
    }
    
.flaticon-agenda:before { content: "\f100"; }
.flaticon-user-1:before { content: "\f101"; }
.flaticon-user:before { content: "\f102"; }
.flaticon-power-symbol-off:before { content: "\f103"; }
.flaticon-power-thin-symbol:before { content: "\f104"; }
.flaticon-logout:before { content: "\f105"; }
.flaticon-receipt:before { content: "\f106"; }
.flaticon-invoice:before { content: "\f107"; }
.flaticon-balance:before { content: "\f108"; }
.flaticon-customer-support:before { content: "\f109"; }

.site-title{
    padding-top: 18px;
}

* {
  font-family: 'Rubik', sans-serif;
 }

 body{
  margin-right: 0px!important;
 }


body{
	font-family: Roboto, sans-serif;
	margin:0;
	margin-left: 8px;
    margin-right: 8px;
}

input[type="checkbox"]{
	font-family: Roboto, sans-serif;
}


.react-grid-Cell{
	border-bottom: 1px solid rgb(224, 224, 224);
	height:40px!important;
}


.react-grid-Canvas{
	overflow-y: auto!important;
	/*top:5px!important;*/
}

.react-grid-Canvas .react-grid-Row .row-selected {
	background-color: rgb(224, 224, 224);
	/*height: 40px!important;*/
}

.react-grid-Cell--locked:last-of-type{
    border-right:none!important;
}

.widget-HeaderCell__value{
    font-weight: normal;
    font-size: 12px;
    padding: 0px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
}

.react-grid-Row.row-selected, .react-grid-Row .row-selected{
    background-color: white!important;
}

.react-grid-Cell.row-selected{
    background-color: #dbecfa!important;
}

.react-grid-HeaderRow{
	border-bottom: 1px solid rgb(224, 224, 224);
}

.react-grid-HeaderCell .react-grid-checkbox-container{
	display: none;
}

.react-grid-HeaderCell__draggable:hover{
	background-color: rgb(103, 103, 103);
}

*:focus {outline:none !important}

.react-grid-HeaderCell-sortable{
	font-weight: normal;
    font-size: 12px;
    padding: 0px;
    /*height: 35px;*/
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
}

.login{
	text-align: center;
	padding-bottom: 20px;
	max-width: 462px;
    margin: 0 auto;
    padding-top: 20px;
}

.registrazione{
	text-align: center;
	padding-bottom: 20px;
	max-width: 825px;
    margin: 0 auto;
    padding-top: 20px;
}

.site-title{
  float:right;
  width: 50%;
}

.login-container{
	margin-top: 12%;
}

.registrazione-container{
    margin-top: 2%;
    margin-bottom: 5%;
}

.btn-login{
	margin-top: 20px;
	margin-left: 169px;
}

input::-ms-clear, input::-ms-reveal {
    display: none;
}

.variante-container h2{
	padding-bottom: 5px;
	margin-bottom: 30px;
    padding-right: 20px;
    border-bottom: 1px #cacaca solid;
    font-size: 22px;
    line-height: 20px;
    font-weight: 300;
    color: rgb(103, 103, 103);
}

.header{
  border-bottom: 1px solid #e4e4e4;
  background-color: rgb(0, 121, 191);;
}

.header .header-content{
  margin-left: 25px;
  height: 64px;
}

.header .header-content .page-title{
    float: left;
    width: 50%
}

.header .header-content .site-title{
    float: right;
    width: 50%;
    text-align: right;
}

.header .header-content .site-title p{
  line-height: 32px;
}

.header .header-content .site-title>div{
  margin-right:10px;
  text-align: left;
  float: right;
}

.header .header-content p{
  margin: 0;
  line-height: 64px;
  font-size: 25px;
  font-weight: 500;
  color: white;
}

.side-logo-container{
  position: absolute;
  bottom:5px;
  text-align: center;
  width: 100%;
}

.filter-container{
  height: 70px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
}

.filter-container .filter-content{
  margin-left: 25px;
}

.filter-container .filter-content .filter-button{
  box-shadow: none;
  border: 1px solid #ced4da;
  background-color: white;
  padding:9px 12px;
  border-radius: 3px;
  margin: 10px;
  text-transform: none;
}

.sidenav---navitem---9uL5T{
  height: 60px;
}


p.picker-label{
    text-transform: uppercase;
    color: #909090;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 20px; 
    display: inline-block;
}

.grid-container{
    padding:20px;
    margin:0 auto 0 40px;
}












.react-grid-Main{
    outline: none!important;
}

.react-grid-Grid{
    border:none!important;
}

.react-grid-Cell{
	border-bottom: 1px solid rgb(224, 224, 224);
	/*height:30px!important;*/
}

.react-grid-Canvas .widget-HeaderCell__value{
    height: 40px;   
}

.widget-HeaderCell__value{
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
    padding: 0px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
}

.react-grid-Header{
   /* height:35px!important;*/
    border-bottom: 1px solid rgb(224, 224, 224);
}

.react-grid-HeaderCell{
   /* height:35px!important;*/
    padding-top:0!important;
    padding-bottom:0!important;
    background-color: white!important;
}

.react-grid-HeaderRow{
    /*height:35px!important;*/
    background-color: white!important;
    border-bottom: 1px solid rgb(224, 224, 224)!important;
}

.react-grid-HeaderRow > div{
	/*height:35px!important;*/
}

.react-grid-HeaderCell .react-grid-checkbox-container{
	display: none;
}

.react-grid-HeaderCell__draggable:hover{
	background-color: rgb(103, 103, 103);
}

.react-grid-HeaderCell{
	font-weight: normal;
    font-size: 14px;
    padding: 0px;
    height: 34px!important;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
    border-right:none!important;
}

.react-grid-HeaderCell-sortable{
	font-weight: normal;
    font-size: 14px;
    padding: 0px;
    /*height: 25px;*/
    text-align: left;
    line-height: 35px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(158, 158, 158);
}

.react-grid-Viewport{
	/*top:25px!important;*/
}

.react-grid-Row{
	/*height:30px!important;*/
	/*line-height: 30px;*/
}

.react-grid-Cell{
    overflow: hidden;
    border:none!important;
}

.react-grid-Cell:hover{
    cursor: pointer;
}

.react-grid-Canvas{
	overflow-y: auto!important;
	/*top:5px!important;*/
}

.react-grid-Canvas .react-grid-Row .row-selected {
	background-color: rgb(224, 224, 224);
	/*height: 30px!important;*/
}

.react-grid-Canvas .widget-HeaderCell__value{
    height: 30px;
    
}



[type="checkbox"]:checked + label:before {
    content: '';

    border: 1px solid rgb(0, 121, 191)!important;
    background: rgb(0, 121, 191)!important;
    /*border-radius: 3px;*/
    box-shadow: none!important;
  }

  /* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 4px; left: 4px;
  font-size: 17px;
  line-height: 0.8;
  color: white;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.action-button{
	text-align: right;
    margin-top: 18px;
    margin-bottom: 18px;
}

.main-container{
    width:90%;
}

.error-message-container{
    margin-top: 10px;
    color: red;
}

.footer-container{
	text-align: left;
    margin-top: 15px;
    margin-left: 10px;
}

.loggedout .header{
    background-color: white;
    border:none;
}

.loggedout .site-title{
    margin-top:25px;
    width:auto;
    /*padding-right:20px;*/
}

.loggedout .site-title p{
    font-size: 25px;
    font-weight: 500;
    color:rgb(0, 121, 191);
    line-height: 32px;
    margin: 0;
}

.shipping-container h2,
.utente-container h2,
.registrazione-container h2{
	/*padding-bottom: 5px;
    padding-right: 20px;
    border-bottom: 1px #cacaca solid;
    font-size: 22px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 5px;
    color: rgb(103, 103, 103);*/
    font-weight: 300;
    margin-bottom: 5px;
    color: rgb(0, 121, 191);
    border: 0;
    text-transform: uppercase;
    font-size: 27px;
}

.button-container{
    margin-top: 50px;
}

.loggedout{
    width: 98%;
}

.utente-container{
    overflow-y: scroll;
    max-height: 88vh;
    padding: 15px 0;
}

.shipping-container{
    min-width: 700px;
}

.print-button{
    margin-top:3%;
    margin-bottom:5%
    /*text-align: left;*/
}

.error-text{
    color:red
}

.fullBG{
	height: 100%;
    top: 0;
    position: fixed;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 2000;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid rgb(0, 121, 191); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 16% auto 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}